





























import { Component, Vue } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';

import { YmsModule } from '@/store/index';

import { DailyInOutHeaders } from './models/DailyInOutHeaders';

import InventoryAction from '@/pages/YMS/components/InventoryAction.vue';

import YMSFilters from '@/pages/YMS/components/YMSFilters.vue';
import { YmsType } from '@/pages/YMS/models/yms.model';
import { ToastHelper } from '@/utils/toast.util';

@Component({
  components: { TmsTable, InventoryAction, YMSFilters }
})
export default class DailyInOut extends Vue {
  YmsModule = YmsModule;
  YmsType = YmsType;
  columns: TableHeaders[] = DailyInOutHeaders;
  inventoryRowData: any = {};

  // get rows() {
  //   const a = [];
  //   for (let index = 0; index < 100; index++) {
  //     a.push({
  //       category: `caategory${index}`,
  //       date: '20210428000000',
  //       orderNumber: `aaaa${index}`,
  //       billTo: `bill to ${index}`,
  //       containerNumber: `number124 ${index}`
  //     });
  //   }
  //   return a;
  // }

  constructor() {
    super();
  }

  get dailyList() {
    return this.YmsModule.ymsData;
  }

  get isLastPage(): boolean {
    return !this.YmsModule.meta.lastKey;
  }

  openModal(rowData) {
    YmsModule.setYmsType(YmsType.DAILY);
    YmsModule.setInventoryId(rowData.data.id);
    this.$bvModal.show('inventory-action-modal');
  }
}
